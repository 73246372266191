import React from "react";
// import { GlobalDataContext } from "../../context/context";
//import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts }) {
  // const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center">
        <video
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full object-cover"
        >
          <source src={urlVideo} type="video/mp4" />
        </video>
        <div className="flex md:flex-col flex-col md:w-4/5 mx-auto absolute md:text-start text-center text-white">
          <div className="w-full text-center md:pt-[300px] pt-[400px]">
            <h1 className="text-[30px] md:text-[3em] lg:text-[3em] mb-2">{title}</h1>
            {/* <p className="px-5 md:px-[5%]">{texts}</p> 
            <ButtonContent btnStyle="three" /> */}
          </div>
          <div className="flex md:flex-row flex-col items-center md:w-[20%] w-full h-auto m-auto justify-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2FRecurso%201.png?alt=media&token=6bb6f1ae-8cec-43c4-a3d0-2583178e30fe"
              alt="year of experience"
              className="md:w-[230px] w-[170px] md:h-[230px] h-[170px] object-cover p-1"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2FInsignia%20de%20Google.png?alt=media&token=465c161a-3b84-4d47-ac48-ab9f218d7cbe"
              alt="insignia google"
              className="md:w-[350px] w-[210px] md:h-[350px] h-[210px] object-cover p-1"
            />
          </div>
        </div>
      </div>
      {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-14 -mt-1 relative md:py-14 py-2 homi">
                    <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
                        <h5 className="flex items-center" data-aos="zoom-in">
                            <GiHammerNails
                                fontSize={70}
                                className="text-white"
                            />
                            BEST SERVICE</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
                            <AiOutlineTeam
                                fontSize={70}
                                className="text-white"
                            />
                            PROFESSIONAL TEAM</h5>
                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
                            <GiRibbonMedal
                                fontSize={70}
                                className="text-white"
                            />BEST QUALITY</h5>
                    </ul>
                </div> */}
    </div>
  );
}
export default HeroVideo;
